import wav_Mystery from "./audio/Mystery.wav"
import mp3_AFQ_Adam_and_Eve from "./audio/AFQ_Adam_and_Eve.mp3"
import mp3_AFQ_Lions_Den from "./audio/AFQ_Daniel.mp3"
import mp3_AFQ_Goliath from "./audio/AFQ_David_and_Goliath.mp3"
import mp3_AFQ_Jericho from "./audio/AFQ_Jericho.mp3"
import mp3_AFQ_Samson from "./audio/AFQ_Samson.mp3"
import mp3_AFQ_Noah from "./audio/AFQ_Noah.mp3"
import mp3_AFQ_Jonah from "./audio/AFQ_Jonah.mp3"

import img_AFQ_Origin from "./images/AFQ_Origin.jpg"
import img_AFQ_Creation_Story from "./images/AFQ_Creation_Story.jpg"
import img_AFQ_Adam_and_Eve from "./images/AFQ_Adam_and_Eve.jpg"
import img_AFQ_Noah from "./images/AFQ_Noah.jpg"
import img_AFQ_Jericho from "./images/AFQ_Jericho.jpg"
import img_AFQ_Samson from "./images/AFQ_Samson.jpg"
import img_AFQ_Goliath from "./images/AFQ_Goliath.jpg"
import img_AFQ_Lions_Den from "./images/AFQ_Lions_Den.jpg"
import img_AFQ_Jonah from "./images/AFQ_Jonah.jpg"
import img_AFQ_Jesus from "./images/AFQ_Jesus.jpg"

interface IDictionary {
    [index: string]: {
        [index: string]: string
    }
}

const AFQAssets = {
    "Origin": {
        "mp3": wav_Mystery,
        "img": img_AFQ_Origin,
        "index": "0",
        "title": "Coming Soon!"
    },
    "Creation_Story": {
        "mp3": wav_Mystery,
        "img": img_AFQ_Creation_Story,
        "index": "1",
        "title": "Coming Soon!"
    },
    "Adam_and_Eve": {
        "mp3": mp3_AFQ_Adam_and_Eve,
        "img": img_AFQ_Adam_and_Eve,
        "index": "2",
        "title": "The Story of Adam and Eve"
    },
    "Noah": {
        "mp3": mp3_AFQ_Noah,
        "img": img_AFQ_Noah,
        "index": "3",
        "title": "The Story of Noah"
    },
    "Jericho": {
        "mp3": mp3_AFQ_Jericho,
        "img": img_AFQ_Jericho,
        "index": "4",
        "title": "The Story of the Battle of Jericho"
    },
    "Samson": {
        "mp3": mp3_AFQ_Samson,
        "img": img_AFQ_Samson,
        "index": "5",
        "title": "The Story of Samson and Delilah"
    },
    "David_and_Goliath": {
        "mp3": mp3_AFQ_Goliath,
        "img": img_AFQ_Goliath,
        "index": "6",
        "title": "The Story of David and Goliath"
    },
    "Daniel": {
        "mp3": mp3_AFQ_Lions_Den,
        "img": img_AFQ_Lions_Den,
        "index": "7",
        "title": "The Story of Daniel and the Lions Den"
    },
    "Jonah": {
        "mp3": mp3_AFQ_Jonah,
        "img": img_AFQ_Jonah,
        "index": "8",
        "title": "The Story of Jonah and the Whale"
    },
    "Jesus": {
        "mp3": wav_Mystery,
        "img": img_AFQ_Jesus,
        "index": "1",
        "title": "Coming Soon!"
    },
} as IDictionary;

export default AFQAssets;