import SongPlayer from './components/SongPlayer'
import EpisodeTile from './components/EpisodeTile';
import './App.css';
import { useEffect, createElement } from 'react';
import AFQAssets from './afq-assets';
import * as Amplitude from "amplitudejs";
import fezimg from './images/fez.png';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();

  useEffect(() => {
    var songs = [];
    for(var i = 0; i < Object.keys(AFQAssets).length; i++) {
      var currentStory = AFQAssets[Object.keys(AFQAssets)[i]];
      songs.push({
        "name": currentStory.title,
        "album": "The Adventures of Feziree and Quessa",
        "url": currentStory.mp3,
        "covert_art_url": currentStory.img
      })
    }
    const songIndex = Object.keys(AFQAssets).indexOf(location.pathname.substring(1));
    Amplitude.init({
      "songs": songs,
      "start_song": songIndex >= 0 ? songIndex : 0
    })
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img id="afq-logo" src={fezimg}></img>
      </header>
      <div id="episode-tile-container">
        {Object.keys(AFQAssets).map((name) => (
          <EpisodeTile name={name}></EpisodeTile>
        ))}
      </div>
      <SongPlayer></SongPlayer>
    </div>
  );
}

export default App;
