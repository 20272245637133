import React from 'react';
import AFQAssets from '../afq-assets';
import CSS from 'csstype';
import './EpisodeTile.css'

type EpisodeTileProps = {
    name: string
}

class EpisodeTile extends React.Component<EpisodeTileProps> {
    episodeTileStyle: CSS.Properties;
    constructor(props: EpisodeTileProps) {
      super(props);
      var bg = "";
      if(AFQAssets[props.name].title == "Coming Soon!") {
        bg = "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),"
      }
      bg += "url(" + AFQAssets[props.name].img + ")"
      this.episodeTileStyle = {
        background: bg,
        backgroundSize: "cover"
      }
    }

    render(): React.ReactNode {
      return <div className='episode-tile amplitude-song-container amplitude-play-pause' data-amplitude-song-index={AFQAssets[this.props.name].index} style={this.episodeTileStyle}>
        <span className="episode-title">{AFQAssets[this.props.name].title}</span>
      </div>
    }
}

export default EpisodeTile;