import React from 'react';

class SongPlayer extends React.Component {
    render(): React.ReactNode {
      return <div id="song-player">
        <div id="song-player-progress">
          <span className="song-player-current-time">
            <span className="amplitude-current-minutes" ></span>:<span className="amplitude-current-seconds"></span>
          </span>
          <div id="progress-container">
            <input type="range" className="amplitude-song-slider"/>
            <progress id="song-played-progress" className="amplitude-song-played-progress"></progress>
            <progress id="song-buffered-progress" className="amplitude-buffered-progress" value="0"></progress>
          </div>
          <span className="duration">
            <span className="amplitude-duration-minutes"></span>:<span className="amplitude-duration-seconds"></span>
          </span>
        </div>
        <div id="song-player-controls">
          <div className="amplitude-repeat amplitude-repeat-off" id="repeat"></div>
          <div className="amplitude-prev" id="previous"></div>
          <div className="amplitude-play-pause" id="play-pause"></div>
          <div className="amplitude-next" id="next"></div>
          <div className="amplitude-shuffle amplitude-shuffle-off" id="shuffle"></div>
        </div>
        <div id="song-player-metadata">
          <span data-amplitude-song-info="name" className="song-name"></span>
          <div className="song-album">
            <span data-amplitude-song-info="album"></span>
          </div>
        </div>
      </div>
    }
}

export default SongPlayer;